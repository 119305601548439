/*
Copyright PT Monit Teknologi Indonesia
All rights reserved.

This document may not be reproduced, republished, distributed, transmitted,
displayed, broadcast or otherwise exploited in any manner without the express
prior written permission of PT Monit Teknologi Indonesia The receipt or possession of this
document does not convey any rights to reproduce, disclose, or distribute its
contents, or to manufacture, use, or sell anything that it may describe, in
whole or in part.
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  height: 100%;
  margin: 0;
  font-family:
    'Inter',
    'Karla',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  overflow: hidden;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-family: inherit;
  font-size: 1em;
}

button:hover {
  cursor: pointer;
}

input:focus {
  outline: none;
}

input[type='range']::-webkit-slider-thumb {
  pointer-events: all;

  width: 24px;
  height: 24px;
  -webkit-appearance: none;
}

input[type='range']::-moz-range-thumb {
  pointer-events: all;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
}

input[type='text']:focus {
  box-shadow: none;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #9d9db0;
  border-radius: 4px;
}
.scrollbar::-webkit-scrollbar {
  width: 0px;
}
.scrollbar-show::-webkit-scrollbar {
  width: 4px !important;
}
.scrollbar:hover::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.scrollbar-hide::-webkit-scrollbar {
  width: 0px;
}

.trx-item-enter {
  opacity: 0;
}
.trx-item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.trx-item-exit {
  opacity: 1;
}
.trx-item-exit-active.animate {
  animation: foo 2s ease forwards;
  transform-origin: left;
}

@keyframes foo {
  from {
    opacity: 1;
    background: #e9f1ed;
    transform: translate(0, 0);
  }

  50% {
    /* transform: scale(2); */
    background: #e9f1ed;
    height: 246px;
  }

  to {
    height: 0;
    opacity: 0;
    background: white;
    color: white;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Toastify__toast-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: fit-content !important;
  max-width: 400px;
}

.Toastify__toast {
  width: fit-content !important;
  max-width: 400px;
  font-family: 'Inter', sans-serif;
}
